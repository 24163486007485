.react-international-phone-country-selector-button {
    border: none;
    background: none;
}
.react-international-phone-country-selector-button:hover {
    border: none;
    background: none;
}
.aboutBox .aboutDes{
    opacity: 0;
    height: 0px;
}
.aboutBox:hover .aboutDes{
    opacity: 1;
    height: 70px;
    transition: all 0.4s;
}