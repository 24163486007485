@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

/*scroll*/
::selection {
  background: #895ffc;
  color: #fff;
  text-shadow: none;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: none;
}

::-webkit-scrollbar-track-piece {
  background-color: #dedede;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 0px;
  height: 20px;
  background-image: linear-gradient(#FFC728, #FFC728);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
table,
thead,
tbody,
tr,
td,
th,
span {
  font-family: 'Poppins', sans-serif !important;
}

a {
  text-decoration: none !important;
}

p {
  font-size: 15px;
  color: #000;
}

.mb .btn-a {
  background-color: #ffc728;
  color: #000;
  font-size: 13px;
}

.mh h4 {
  font-size: 20px;
  color: #000;
}

.mh h5 {
  font-size: 17px;
  color: #000;
}

.mh h3 {
  font-size: 30px;
  color: #000;
}

.ybox {
  transform: rotate(11deg);
  position: absolute;
  right: -3%;
  top: -7%;
  width: 900px;
  height: 1200px;
  background: #ffc00f;
}

.yboxone {
  transform: rotate(334deg);
  position: absolute;
  right: -3%;
  bottom: -21%;
  width: 996px;
  height: 550px;
  background: #203863;
  border-radius: 50px;
}

.yboxtwo {
  transform: rotate(321deg);
  position: absolute;
  right: -7%;
  bottom: -39%;
  width: 996px;
  height: 550px;
  background: #27406d;
  border-radius: 50px;
}

.test-wrap {
  position: relative;
}

.test-wrap.t {
  padding-left: 10%;
}

.test-wrap.b {
  padding-left: 15%;
}

.testimg img {
  border-radius: 30px;
}

.testb {
  width: 342px;

  color: #000;
  position: absolute;
  padding: 12px 14px;
  border-radius: 16px;
  background: #fff;
}

.testb h5 {
  margin-bottom: 5px;
}

.tr {
  right: 16%;
  top: 2%;
}

.tl {
  left: 0;
  bottom: -25%;
}

.testb.tr span {
  position: absolute;
  top: -18%;
  left: 0;
}

.testb.tl span {
  position: absolute;
  right: -63px;
  top: 29px;
}

.Ifiled svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 9px;
}

.Ifiled input,
.Ifiled select,
.Ifiled textarea {
  padding: 23px 20px;
  background-color: #EAEAEA;
  font-size: 14px;
}

.Ifiled select {
  padding: 0 20px;
  height: 48px;
  background-color: #EAEAEA;
  font-size: 14px;
}

.Ifiled button {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 1;
  background-color: transparent;
}

.Ifiled button:hover {
  background-color: transparent;
}

.Ifiled {
  position: relative;
  margin-top: 0 !important;
}

.cterm a {
  color: #203863;
  font-weight: 600;
}

.pfiled input {
  outline: 1px solid #fff;
  background: #EAEAEA;
  outline-offset: 0;
  padding: 25px 24px;
  width: auto;
}

ul.sidemenu>li {
  margin-bottom: 20px;
}

ul.sidemenu>li>a {
  background-color: transparent;
  padding: 12px 10px;
  max-width: 235px;
  display: block;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
}

ul.sidemenu>li>a:hover {
  background-color: #ffc728;
}

ul.sidemenu>li>a svg {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 8px;
}

ul.sidesubmenu>li {
  margin-bottom: 15px;
}

ul.sidesubmenu>li:last-child {
  margin-bottom: 0;
}

ul.sidesubmenu>li>a {
  font-size: 14px;
  font-weight: 500;
}

.dbtn a {
  border: 1px solid #000;
  display: flex;
  align-items: center;
  padding: 9px 12px;
  border-radius: 8px;
}

.dbtn a img {
  width: 40px;
}

.issbtn button {
  padding: 25px 0px;
  width: 200px;
}

.Scrolwrap {
  height: 645px;
  overflow-y: auto;
  overflow-x: hidden;
}

.boxlink a {
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Ifiled input.filetype {
  position: relative;
}

.Ifiled input.filetype:after {
  position: absolute;
  /* content: 'Upload'; */
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  border: 1px dashed #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.btn button {
  transform: skew(-8deg);
  -webkit-transform: skew(-8deg);
  -moz-transform: skew(-8deg);
  /* background-image: url(../images/greenBtnTexture.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.btn button span {
  transform: skew(-8deg);
  -webkit-transform: skew(8deg);
  -moz-transform: skew(-8deg);
}

.btn p {
  transform: skew(-8deg);
  -webkit-transform: skew(-8deg);
  -moz-transform: skew(-8deg);
}

.btn p span {
  transform: skew(-8deg);
  -webkit-transform: skew(8deg);
  -moz-transform: skew(-8deg);
  display: block;
}

.btn>div {
  transform: skew(-8deg);
  -webkit-transform: skew(-8deg);
  -moz-transform: skew(-8deg);
}

.btn>div span {
  transform: skew(8deg);
  -webkit-transform: skew(8deg);
  -moz-transform: skew(8deg);
}

.loggedBtn span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addItemForm>label {
  margin: 15px 0 0 0 !important;
}

.addItemForm label {
  font-weight: 600;
}

.addItemForm input,
.addItemForm select,
.addItemForm textarea {
  background-color: #F4F4F4;
  color: #AFAFAF;
  border: 1px solid #AFAFAF;
  border-radius: 5px;
}

.settingsForm label {
  font-weight: 600;
}

.settingsForm input {
  background-color: #EAEAEA;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
}

.orderDetModal>div {
  font-weight: 500;
  margin: 0 0 10px 0 !important;
  color: #28551A;
}

.orderDetPersonal>div {
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 10px 0 !important;
}

.Ifiled .react-international-phone-input-container input {
  width: 100%;
}

#googleMaps .place-card {
  display: none !important;
}

.place-card .place-card-medium {
  display: none !important;
}

.signaturePad {
  border: 2px solid #adadad;
}

.signaturePadHide {
  border: none;
}

.MobileView {
  display: none;
}

ul.pagination {
  display: flex;
  gap: 15px;
  list-style: none;
}

ul.pagination>li>a {
  padding: 5px;
  border: 2px solid #28551A;
  border-radius: 4px;
  width: 35px;
  display: block;
  font-weight: 500;
  color: #28551A;
  text-align: center;
  height: 35px;
}

ul.pagination>li.active>a {
  background: #28551A;
  color: #fff;
}

/* className="desktopView" */
/* className="MobileView" */

@media (max-width: 1024px) {
  .MobileView {
    display: initial;
  }

  .desktopView {
    display: none;
  }
}